/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import {
  GatsbyImage,
  StaticImage,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Link from "../components/link/link"
import { Button, ButtonLink } from "../components/button/button"
import SectionTitle from "../components/section-title/section-title"
import NewsItem from "../components/news-item/news-item"
import ImageTextSummary from "../components/image-text-summary/image-text-summary"
import ModalContactForm from "../components/modal-contact-form/modal-contact-form"
import Metadata, { buildSocialImage } from "../components/metadata/metadata"
import Carousel from "../components/carousel/carousel"
import { UnbulletedList } from "../components/flat-lists/flat-lists"
import FullBleedQuote from "../components/full-bleed-quote/full-bleed-quote"
import { colors } from "../gatsby-plugin-theme-ui"
import PricingIcon from "../images/inline-svg/pricing.svg"
import AnalyticsIcon from "../images/inline-svg/analytics.svg"
import ContentIcon from "../images/inline-svg/content.svg"
import ContactForm from "../components/contact-form/contact-form"
import { useRef } from "react"

export default function Home({ data }) {
  const socialImage = buildSocialImage({
    title: data.imageSocial.name,
    ...getImage(data.imageSocial),
  })
  const initialFocusRef = useRef()
  const { newsImages } = data
  const newsItems = [
    {
      text: (
        <q>
          By training in a simulated environment you can get reps and prepare
          yourself. That way if you ever need to have one of these tough
          conversations in the real world, you’ll be ready.
        </q>
      ),
      image: getImage(newsImages.nodes.find(img => img.name === "dungy")),
      alt: "Tony Dungy",
      attribution: {
        name: "Coach Tony Dungy",
        title: "Super Bowl Winning NFL Coach",
      },
    },
    {
      text: (
        <q>
          With this being my first experience with VR I was hesitant to sign up,
          but I was pleasantly surprised with how easy it was. The training was
          very engaging and I look forward to taking another VR training soon.
        </q>
      ),
      image: getImage(
        newsImages.nodes.find(img => img.name === "oil-refinery")
      ),
      alt: "Oil & Gas Company",
      attribution: {
        name: "Nicole Larson",
        title: "Fortune 500 Oil & Gas Company",
      },
    },
    {
      text: (
        <q>
          We believe this training will not only save lives but prepare our
          Airmen for tough conversations that will build a more resilient force.
        </q>
      ),
      image: getImage(newsImages.nodes.find(img => img.name === "air-force")),
      alt: "US Air Force",
      attribution: {
        name: "Program Manager",
        title: "AMC Suicide Prevention Program Mgr, USAF",
      },
    },
    {
      text: (
        <q>This is something our learners just can’t get from a textbook.</q>
      ),
      image: getImage(
        newsImages.nodes.find(img => img.name === "accenture-black")
      ),
      attribution: {
        name: "Molly Tierney",
        title: "Child Services Division, Accenture",
      },
    },
    {
      text: (
        <q>
          VR is emerging as a valuable tool to help address racial bias that in
          many instances may be unconscious or unintended.
        </q>
      ),
      to:
        "https://www.govtech.com/opinion/how-virtual-reality-training-can-help-combat-racial-bias.html",
      image: getImage(
        newsImages.nodes.find(img => img.name === "michael-mcaffe")
      ),
      alt: "Michael McAfee",
      attribution: {
        name: "Michael McAfee",
        title: "Policylink",
      },
      isPortrait: true,
    },
    {
      text: (
        <q>
          Through virtual reality training Moth+Flame has created a safe space
          for staff to practice difficult conversations involving diversity,
          equity and inclusion. A much needed tool for cultural transformation.
        </q>
      ),
      image: getImage(
        newsImages.nodes.find(img => img.name === "karen-baynes-dunning")
      ),
      alt: "Karen Baynes-Dunning",
      attribution: {
        name: "Karen Baynes-Dunning",
        title: "Baynes Dunning Consulting, LLC",
      },
      isPortrait: true,
    },
    {
      text: (
        <q>
          Among those ages 18 to 25 — a generation more used to interactive
          virtual experiences that makes up the bulk of new recruits — the
          impact increased sevenfold.
        </q>
      ),
      to:
        "https://www.nytimes.com/2021/05/29/us/politics/air-force-vr-virtual-reality.html",
      image: getImage(newsImages.nodes.find(img => img.name === "nytimes")),
      alt: "New York Times",
    },
  ]
  const quote1images = withArtDirection(getImage(data.quote1Desktop), [
    {
      media: "(max-width: 48em)",
      image: getImage(data.quote1Mobile),
    },
  ])
  const quote3images = withArtDirection(getImage(data.quote3Desktop), [
    {
      media: "(max-width: 48em)",
      image: getImage(data.quote3Mobile),
    },
  ])
  return (
    <Layout>
      <Metadata
        title="Moth+Flame | Virtual Reality"
        description="Moth+Flame is an award-winning developer of immersive training technology, pioneering the next decade of virtual reality learning for the private and public sector."
        image={socialImage}
        isHome
      />
      <Panel sx={{ pb: ["3rem", "6rem", "8rem"], pt: [null, "6rem", "8rem"] }}>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 3", "span 4"],
            pt: ["3rem", "unset"],
          }}
        >
          <Themed.h1
            className="scaleText"
            sx={{
              fontFamily: "Montserrat",
              "--max-font-size": [2.2, 3.2, 5.2],
              fontWeight: "600",
            }}
          >
            Transformative impact at scale, <em>powered by data you can see</em>
          </Themed.h1>
          <Themed.p
            sx={{
              fontSize: ["1.1rem", "1.2rem", "1.6rem"],
              lineHeight: [1.2, 1.4, 1.6],
              mt: "6rem",
              mb: "6rem",
              justifyContent: "right",
              alignContent: "right",
              alignItems: "right",
              fontWeight: "600",
            }}
          >
            Empower your team with advanced readiness assessments and
            world-class virtual reality experiences for optimal performance.
          </Themed.p>
          <div
            sx={{
              display: "flex",
              gap: "1rem",
              mt: "4rem",
            }}
          >
            <ButtonLink to="/contact">Contact M+F</ButtonLink>
          </div>
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: ["1.2rem", "1.4rem", "1.6rem"],
              lineHeight: [1.8, 1.6],
              mt: "20rem",
              pt: ["2rem", null, "4rem"],
              pl: "1rem",
              position: "relative",
              "&:after": {
                content: '""',
                height: "3px",
                width: ["81px", "111px"],
                position: "absolute",
                top: 0,
                left: 0,
                backgroundImage: `linear-gradient(to right, ${colors.lightGray} 25px, transparent 25px, transparent 26px, ${colors.lightGray} 26px, ${colors.lightGray} 100%)`,
              },
            }}
          >
            <StaticImage
              placeholder="none"
              src="../images/nytimes.png"
              alt="New York Times"
              sx={{
                maxWidth: "3rem",
                mr: "2rem",
              }}
            />
            <Link
              to="https://www.nytimes.com/2021/05/29/us/politics/air-force-vr-virtual-reality.html"
              target="_blank"
            >
              To Stem Suicide and Sexual Assault, the Air Force Dons Headsets
            </Link>
          </div>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            gridRowStart: ["1", "unset"],
            mx: ["-3rem", "unset"],
            mt: ["3rem", 0],
          }}
        >
          <Carousel
            childrenStyles={{
              flexBasis: "100%",
            }}
            showDots
            loop
            autoplay
          >
            <React.Fragment>
              {data.firstPanelImages.nodes.map(({ name, childImageSharp }) => (
                <GatsbyImage
                  key={name}
                  image={childImageSharp.gatsbyImageData}
                  alt={name}
                  imgStyle={{ objectFit: "contain" }}
                />
              ))}
            </React.Fragment>
          </Carousel>
        </div>
      </Panel>
      <FullBleedQuote
        images={quote1images}
        quote="We’ve seen first hand how the Moth+Flame platform helped a client reduce turnover among first-year employees in from 53% to 19%."
        attribution={{
          name: "MOLLY TIERNEY",
          title: "Managing Director, Accenture",
        }}
        quoteImage={getImage(data.accenture)}
        alignment="right"
        addQuoteLink={true}
      />
      <Panel sx={{ bg: "bgGray", py: ["4rem", "6rem", "8rem"] }} id="why-vr">
        <SectionTitle sx={{ gridColumn: "1 / -1" }}>
          Why is our training different?
        </SectionTitle>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
          }}
        >
          <Themed.h1 className="scaleText">
            <em>Discover real-world readiness </em>
            with immersive VR
          </Themed.h1>
          <Themed.p
            sx={{
              fontSize: ["1.9rem", "2.0rem", "2.6rem"],
              lineHeight: [1.8, 1.9, 2.0],
              fontFamily: "Montserrat",
              fontWeight: "100",
            }}
          >
            Personalized training for optimal performance
          </Themed.p>
          <Themed.p
            sx={{
              fontSize: ["1.9rem", "2.0rem", "2.4rem"],
              lineHeight: [1.8, 1.9, 2.0],
              fontFamily: "Montserrat",
              fontWeight: "100",
            }}
          >
            AI-driven insights for unmatched accuracy
          </Themed.p>
          <Themed.p
            sx={{
              fontSize: ["1.3rem", "1.4rem", "1.8rem"],
              lineHeight: [1.8, 1.9, 2.0],
              fontFamily: "Montserrat",
              fontWeight: "100",
            }}
          >
            Transform data into action. Reach expertise faster and increase your
            team’s capacity.
          </Themed.p>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            gridRowEnd: [null, "span 2"],
            perspective: [null, "500px"],
          }}
        >
          <UnbulletedList
            sx={{
              fontSize: ["1.5rem", "1.3rem", "2rem"],
              lineHeight: [1.47, 1.6],
              display: [null, "flex"],
              flexWrap: "wrap",
              justifyContent: "space-between",
              transform: [null, "rotateY(-5deg)"],
              li: {
                mb: ["2.2rem", "2.4rem", "4rem"],
                flexBasis: "47%",
                borderRadius: [null, "1.6rem"],
                bg: [null, "#fff"],
                p: [null, "2.2rem", "4rem"],
              },
              span: {
                fontSize: [null, "2.8rem", "6rem"],
                lineHeight: [null, 1, 0.8],
                color: [null, "blue"],
                display: [null, "block"],
                mb: [null, "1rem", "2rem"],
                fontWeight: [null, 600],
              },
            }}
          >
            <li>
              <strong>
                <span>90%</span>
              </strong>{" "}
              of participants report{" "}
              <strong>maximum or improved confidence</strong>
            </li>
            <li>
              <strong>
                <span>98%</span> leadership approval
              </strong>{" "}
              of immersive experience training
            </li>
            <li>
              <strong>
                <span>10x</span> more cost effective
              </strong>{" "}
              than traditional classroom and onsite methods
            </li>
            <li>
              <strong>
                <span>4x</span> faster
              </strong>{" "}
              than traditional classroom and onsite training methods
            </li>
          </UnbulletedList>
        </div>
      </Panel>
      <Panel
        sx={{ pt: "4rem", pb: ["4rem", "6rem", "8rem"] }}
        id="enterprise-ready"
      >
        <SectionTitle sx={{ gridColumn: "1 / -1" }} center>
          Enterprise Ready
        </SectionTitle>
        <Themed.h1
          className="scaleText"
          sx={{
            gridColumn: "1 / -1",
            textAlign: "center",
            //mb: ["5rem", "6rem", "10rem"],
          }}
        >
          Drive effectiveness like never before.
          <br />
          <em>Fast. Affordable. Easy.</em>
        </Themed.h1>
        <div
          sx={{
            gridColumn: "1 / -1",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: ["wrap", null, "unset"],
            "> *": {
              flexBasis: ["48%", null, "23%"],
              mb: ["2rem", null, 0],
            },
            a: {
              bg: "bgGray",
              textDecoration: "none",
              color: "inherit",
              borderRadius: "1.6rem",
              "&:hover": {
                color: "inherit",
                bg: "#d5d7d8",
              },
              ":visited": {
                color: "inherit",
              },
            },
          }}
        >
          <Link to="/perform-analytics-platform">
            <ImageTextSummary
              image={
                <StaticImage
                  placeholder="none"
                  src="../images/scale.png"
                  alt="iPad and VR headset"
                  objectFit="contain"
                />
              }
              title="Scale"
              description="Platform designed for large-scale deployment & administration on
              both VR headsets & mobile devices."
            />
          </Link>
          <Link to="/pricing">
            <ImageTextSummary
              image={<PricingIcon />}
              title="Pricing"
              description="Simple pricing from easy-to-launch starter packages to enterprise wide deployments."
            />
          </Link>
          <Link to="/perform-analytics-platform">
            <ImageTextSummary
              image={<AnalyticsIcon />}
              title="Analytics"
              description="Performance tracking at the learner, cohort, or org. level across
              a variety of customizable KPIs."
            />
          </Link>
          <Link to="#how-we-create-content">
            <ImageTextSummary
              image={<ContentIcon />}
              title="Content"
              description="Experiences designed by subject matter experts created with
              Hollywood production quality."
            />
          </Link>
        </div>
      </Panel>
      <Panel
        sx={{ bg: "bgGray", py: ["4rem", "6rem", "8rem"] }}
        id="platform-agnostic"
      >
        <SectionTitle sx={{ gridColumn: "1 / -1" }} center>
          Platform Agnostic
        </SectionTitle>
        <div
          sx={{
            gridColumnStart: ["1"],
            gridColumnEnd: ["span 6", "span 8", "span 12"],
            mb: ["4rem", "unset"],
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Themed.h1
            className="scaleText"
            sx={{ "--max-font-size": [1.6, 2.8, 5.7] }}
          >
            Outdated training methods won’t cut it anymore.
          </Themed.h1>
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <Themed.p>
              Our off-the-shelf immersive experiences can be easily <br />
              deployed across your organization to VR and iOS devices.
            </Themed.p>
          </div>
          <div>
            <div
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <ButtonLink to="/learning-libraries">
                See the full catalog
              </ButtonLink>
            </div>
            <StaticImage
              alt="New training methods"
              sx={{
                maxWidth: ["40rem", null, "90rem"],
                mt: "10rem",
              }}
              src="../images/new-training-methods.png"
            />
          </div>
        </div>
      </Panel>
      <Panel sx={{ py: ["4rem", "6rem", "8rem"] }} id="how-we-create-content">
        <SectionTitle
          sx={{ gridColumnStart: 1, gridColumnEnd: ["-1", "span 4", "span 7"] }}
        >
          Create Your Own Immersive Learning
        </SectionTitle>
        <div
          sx={{
            gridColumnStart: [1, "unset"],
            gridColumnEnd: ["-1", "span 4", "span 4"],
            justifySelf: [null, "end"],
            pr: [null, "4rem", 0],
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/create.png"
            alt="Create"
            sx={{ mb: ["3rem", 0], maxWidth: ["19rem", null, "28rem"] }}
          />
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            mb: ["4rem", "unset"],
          }}
        >
          <Themed.h1 className="scaleText">
            Customize scenario-based experiences <em>in minutes</em>
          </Themed.h1>
          <Themed.p
            sx={{
              variant: "text.intro",
              fontWeight: "100",
              fontSize: [null, "2.5rem", "2.9rem"],
            }}
          >
            Tailored solutions for your unique needs
          </Themed.p>
          <Themed.p sx={{ fontWeight: "100" }}>
            We have assembled a team, production process, and suite of no-code
            creator tools that are unparalleled in achieving the highest quality
            results, in record time, at a fraction of the cost, with
            out-of-the-box, ROI-validating performance analytics tuned to an
            award-winning learning format.
          </Themed.p>
          <div sx={{ objectFit: "contain", margin: "8rem 0 0 0" }}>
            <StaticImage
              placeholder="none"
              src="../images/how-we-create-content.png"
              alt="National Urban League"
              sx={{
                maxWidth: "50rem",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            px: [null, "1rem", "4rem"],
            pt: [null, "8rem", 0],
            perspective: [null, "500px"],
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/create-home-panel.png"
            alt="Create tool"
            sx={{
              transform: [null, "rotateY(-4deg) skewY(1deg)"],
            }}
          />
        </div>
      </Panel>
      <Panel sx={{ bg: "bgGray", py: ["4rem", "6rem", "8rem"] }} id="ready">
        <SectionTitle
          sx={{ gridColumnStart: 1, gridColumnEnd: ["-1", "span 4", "span 7"] }}
        >
          READY hard skills training
        </SectionTitle>
        <div
          sx={{
            gridColumnStart: [1, "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            justifySelf: [null, "end"],
            pr: [null, "4rem", 0],
          }}
        >
          <StaticImage
            src={"../images/ready-horizontal.png"}
            sx={{ maxWidth: "40rem" }}
          />
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            mb: ["4rem", "unset"],
          }}
        >
          <Themed.h1 className="scaleText">
            Stay ahead with <em>continuous improvement</em>
          </Themed.h1>
          <Themed.p sx={{ variant: "text.intro", fontWeight: "100" }}>
            Diagnostic analytics for proactive management
          </Themed.p>
          <Themed.p sx={{ fontSize: "2rem", fontWeight: "100" }}>
            Moth+Flame creates simulations for the world’s most sophisticated
            aircraft. We provide quick-to-deploy training solutions that
            accelerate speed to proficiency with verifiable metrics.
          </Themed.p>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            px: [null, "1rem", "4rem"],
            pt: [null, "8rem", 0],
            perspective: [null, "500px"],
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/ready-cockpit-training.png"
            alt="In-cockpit hard skills training for US Airforce"
          />
        </div>
      </Panel>
      <FullBleedQuote
        images={quote3images}
        quote="Moth+Flame’s risk assessment tools harness advanced technology to pinpoint an individual’s critical vulnerabilities, enabling us to have a deeper understanding of potential challenges."
        alt="Soldiers wearing VR headsets in training"
        attribution={{
          title: "DoD Senior Human Analyst",
        }}
        alignment="right"
      />
      <Panel sx={{ py: ["4rem", "6rem", "8rem"] }}>
        <SectionTitle sx={{ gridColumn: "1 / -1" }}>
          The latest from Moth+Flame VR
        </SectionTitle>
        <Themed.h1
          className="scaleText"
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
          }}
        >
          Enterprise customers <em>succeeding</em> with{" "}
          <em>true-to-life experiences</em> and <em>assessments:</em>
        </Themed.h1>
        <div
          sx={{
            display: ["none", "block"],
            gridColumnEnd: [null, "span 4", "span 5"],
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/mask-facing-left.jpg"
            alt="VR Mask"
          />
        </div>
        <Carousel
          childrenStyles={{
            flexBasis: ["80%", "22%"],
            mr: "3rem",
          }}
          showArrows
        >
          {newsItems.map(item => (
            <NewsItem key={item.alt} {...item} />
          ))}
        </Carousel>
      </Panel>
      <Panel sx={{ py: ["4rem", "6rem", "8rem"] }}>
        <SectionTitle sx={{ gridColumn: "1 / -1" }}>
          About Moth+Flame
        </SectionTitle>
        <Themed.h1
          className="scaleText"
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: ["-1", "span 4", "span 5"],
            gridRowStart: [2],
          }}
        >
          <em>The power of VR</em> to tell important stories
        </Themed.h1>
        <Themed.p
          sx={{
            variant: "text.intro",
            gridColumnStart: 1,
            gridColumnEnd: ["-1", "span 4", "span 5"],
            gridRowStart: [4, 3],
            fontWeight: "100",
          }}
        >
          What if your organization could leverage advanced risk assessments to
          enhance safety and performance, at a fraction of the cost, with
          game-changing results?
        </Themed.p>
        <div
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: ["-1", "span 4", "span 5"],
            gridRowStart: [6, 4],
          }}
        >
          <Themed.p sx={{ mb: "6rem" }}>
            Our journey began in 2015 as we pioneered innovative virtual
            experiences across a range of verticals. We’ve brought the depth of
            our experience to the extraordinary challenge of immersive learning.
          </Themed.p>
          <ButtonLink to="/about/">Learn more about Moth+Flame</ButtonLink>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset", "6"],
            gridColumnEnd: ["-1", "span 4", "-1"],
            gridRowStart: ["5", "unset"],
            gridRowEnd: [null, "span 3"],
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/customers.png"
            alt="Moth+Flame customers"
          />
        </div>
      </Panel>
      <Panel sx={{ bg: "bgGray", py: ["4rem", "6rem", "8rem"] }}>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            mt: ["15rem", "unset"],
          }}
        >
          <Themed.h1 className="scaleText">
            Ready to <em>revolutionize your readiness?</em>
          </Themed.h1>
          <Themed.p sx={{ fontSize: "3rem", mt: "10rem", fontWeight: "100" }}>
            Contact us now to transform your training into powerful insights and
            drive continuous improvement.
          </Themed.p>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset", "span 6"],
            gridColumnEnd: ["-1", "span 4", "-1"],
          }}
        >
          <ContactForm firstFieldRef={initialFocusRef} />
        </div>
      </Panel>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    newsImages: allFile(
      filter: {
        name: {
          in: [
            "nytimes"
            "air-force"
            "michael-mcaffe"
            "karen-baynes-dunning"
            "popsugar"
            "accenture"
            "oil-refinery"
            "dungy"
            "accenture-black"
          ]
        }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
    firstPanelImages: allFile(
      filter: { relativeDirectory: { eq: "home-first-panel" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
    imageSocial: file(name: { eq: "mask-social" }) {
      name
      childImageSharp {
        gatsbyImageData(width: 1200, layout: FIXED, formats: JPG)
      }
    }
    quote1Desktop: file(name: { eq: "mask-group-108" }) {
      name
      childImageSharp {
        gatsbyImageData(width: 1073)
      }
    }
    quote1Mobile: file(name: { eq: "mask-group-108-mobile" }) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
    quote3Desktop: file(name: { eq: "dod-training" }) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
    quote3Mobile: file(name: { eq: "dod-training-mobile" }) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
    accenture: file(name: { eq: "accenture" }) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    libraries: allPrismicLearningLibrary(
      sort: { fields: data___sort_order, order: ASC }
      filter: { uid: { ne: "ready" } }
    ) {
      nodes {
        data {
          title {
            text
          }
          short_description {
            text
          }
          thumbnail_image {
            gatsbyImageData
          }
        }
        uid
      }
    }
  }
`
